import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required * "),
  password: yup.string().required("Password is Required *"),
});

const LoginScreen = () => {
  const [partnerId, setPartnerId] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch("/config.json");
      const data = await response.json();
      setConfig(data);
      setPartnerIdCookie(data.partnerId);
    } catch (error) {
      console.error("Error loading config.json:", error);
    }
  };

  const setPartnerIdCookie = (partnerId) => {
    Cookies.set("partnerId", partnerId, {
      domain: "prabalta.org",
      path: "/usproject",
      secure: true,
      sameSite: "None",
      expires: 1, // 1-day expiry
    });

    Cookies.set("partnerId", partnerId, {
      domain: ".prabalta.org",
      path: "/",
      secure: true,
      sameSite: "None",
      expires: 1, // 1-day expiry
    });

    Cookies.set("partnerId", partnerId, {
      domain: 'localhost',
      path: "/",
      secure: false,
      sameSite: "Lax",
      expires: 1, // 1-day expiry
    });
  };

  const onSubmitHandler = (data) => {
    setLoading(true);

    fetch(`${TQ_URL}/user`, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa(data.email + ":" + data.password),
        "Content-Type": "application/json",
        "Partner-Id": config.partnerId,
      },
    })
      .then((response) => {
        setLoading(false);

        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "An error occurred");
          });
        }

        const authorizationHeader = response.headers.get("Authorization");
        const token = authorizationHeader;

        if (token) {
          loggedInNotify();
          localStorage.setItem("accessToken", token);
          return response.json();
        }

        throw new Error("Token not found in response");
      })
      .then((data) => {
        localStorage.setItem("loggedIn", true);
        localStorage.setItem(
          "userData",
          JSON.stringify({
            userId: data.user.userId,
            userName: data.user.userName,
            email: data.user.email,
            phone: data.user.phone,
            userType: data.user.userType,
            imageUrl: data.user.imageUrl,
          })
        );

        localStorage.setItem(
          "userAddressDetails",
          JSON.stringify({ addressDetails: data.addressDetail })
        );

        if (
          data.user.userType === "Organization" ||
          data.user.userType === "Individual"
        ) {
          navigate("/");
        } else if (data.user.userType === "Admin") {
          navigate("/money-details");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Invalid Credentials!");
      });

    reset();
  };

  const loggedInNotify = () => toast.success("Logged In Successfully!");

  const initiateGoogleLogin = () => {
    setPartnerIdCookie(config.partnerId);
    window.location.href = `http://prabalta.org:8087/usproject/oauth2/authorize/google?redirect_uri=${encodeURIComponent(config.redirect_uri)}`;
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {loading && (
        <div className="fixed top-0 left-0 right-0 h-1 loader-bg-color z-50"></div>
      )}

      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left login-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-start justify-end md:px-20 px-5">
          <h3 className="text-white txt-style-1 mb-5">
            Work For charity? <br />
            Log in for a Charity Account?
          </h3>
          <h3 className="text-white txt-style-2 mb-10">
            Unlock Hope, <br />
            Sign In Here
          </h3>
        </div>
      </div>

      {/* Right Section */}
      <div className="md:flex-1 flex items-center justify-start p-10 sm:p-10 md:ms-10 max-w-screen-lg m-auto w-full h-full fade-in-right">
        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl ">
          {/* Logo */}
          <div className="text-center md:text-left mb-20 sm:mb-16 w-full flex justify-center">
            <Link to="/">
              <img
                src={config.logoUrl}
                alt="Logo"
                className="h-10 sm:mx-0"
              />
            </Link>
          </div>

          {/* Email and Password Fields */}
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="mb-6">
              <input
                {...register("email")}
                placeholder="Email_ID"
                type="email"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div>
            <div className="mb-6 relative">
              <input
                {...register("password")}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.password && "border-red-500"
                }`}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
              <p className="text-red-500 text-sm mt-1">
                {errors.password?.message}
              </p>
            </div>
            {/* Forgot Password Text */}
            <div className="mb-6 text-center">
              <Link
                to="/forgetpassword"
                className="text-sm text-blue-500 underline"
              >
                <i>Forgot Your Password?</i>
              </Link>
            </div>
            <button
              type="submit"
              className="w-full text-white p-2 rounded-md mb-6 btn-style-1"
            >
              Sign in
            </button>

            {/* Sign Up Link */}
            <div className="my-6 text-center text-dark-600">
              Don't have an account?
              <Link to="/createaccount">
                <span style={{ color: "#F7B70D", fontWeight: 700 }}>
                  {" "}
                  Sign Up
                </span>
              </Link>
            </div>
            <div className="flex flex-col justify-center space-x-4">
              <a className="text-sm text-dark-600 text-center">
                <i> Or Sign Up With </i>
              </a>
            </div>
          </form>

          <div className="flex justify-center space-x-4 mt-5">
            <a
              onClick={initiateGoogleLogin}
              className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 488 512"
              >
                <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
              </svg>
            </a>

            <a
              href={`https://prabalta.org:8087/usproject/oauth2/authorize/facebook?redirect_uri=${encodeURIComponent(config.redirect_uri)}`}
              className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </div>

          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
