import React, { useCallback, useEffect, useState, useMemo } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  ActionIcon,
  Box,
  Button,
  Loader,
  MantineProvider,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import TopHeader from "../Home/TopHeader";
import HistorySidebar from "../Home/HistorySidebar";
import ReceiptPopup from "../Common/ReceiptPopup";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

const DonorsHistory = () => {
  // const [userData, setUserData] = useState([]);
  const [donationData, setDonationData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showReceiptPopup, setShowReceiptPopup] = useState(false);

  const [receiptPopupData, setReceiptPopupData] = useState(null);

  useEffect(() => {
    if (!donationData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getDonorsHistory();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
  ]);

  const handleViewReceipt = (rowdata) => {
    console.log("view reciept called ", rowdata.original.fundraiserDetailDTO);
    setShowReceiptPopup(true);
    setReceiptPopupData(rowdata.original);
  };

  const getDonorsHistory = async () => {
    const url = new URL(`${TQ_URL}/getAllDonationMadeByLoginUser`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    try {
      const response = await getRequestWithTokenWithoutData(url.toString());

      //  setDonationData(response);
      const dataWithSerialNumber = response.dataList.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.dataList);

      setDonationData(dataWithSerialNumber);
      setRowCount(response.totalCount);
      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the donor's history:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      setIsRefetching(false);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "formattedDate",
        header: "Date",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "fundraiserCausename",
        header: "Fundraiser/Cause Name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "amount",
        header: "Donated Amount",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "donationStatus",
        header: "Status",

        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          // Custom rendering for the "Status" column
          <Box
            sx={(theme) => ({
              backgroundColor:
                row.original.donationStatus === "Complete" ||
                row.original.donationStatus === "paid"
                  ? "#DAF4EB"
                  : row.original.donationStatus === "Cancel" ||
                    row.original.donationStatus === "Cancelled"
                  ? "#FDE4E4"
                  : row.original.donationStatus === "Pending"
                  ? "#FFE0A1"
                  : "inherit",
              color:
                row.original.donationStatus === "Complete" ||
                row.original.donationStatus === "paid"
                  ? theme.colors.green[9]
                  : row.original.donationStatus === "Cancel"
                  ? "#FF0000"
                  : row.original.donationStatus === "Pending"
                  ? "#FFA200"
                  : "inherit",
              borderRadius: "4px",
              padding: "4px",
              width: "80px",
              textAlign: "center",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: donationData,
    enableRowActions: true,
    positionActionsColumn: "last",
    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,

    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <Button onClick={() => handleViewReceipt(row)} variant="outline">
          View Receipt
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <HistorySidebar />
        </div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">Donor's History</h2>
                {/* <p className="text-black font-medium leading-10">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
              </div>
              {/*   <div className="flex items-center space-x-4 mt-6 md:mt-0 justify-center">
                <div className="user-img-box w-24 h-24 mb-4 overflow-hidden rounded-full relative cursor-pointer">
                  <img
                    src="assets/images/user-1.jpg"
                    alt="Document Preview"
                    className="object-cover w-full h-full bg-grey-500 cursor-pointer"
                  />
                </div>
                <p>John Doe</p>
                <svg
                  viewBox="0 0 16 16"
                  className="w-4"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <rect
                      width="16"
                      height="16"
                      id="icon-bound"
                      fill="none"
                    ></rect>
                    <polygon points="14.586,3.586 8,10.172 1.414,3.586 0,5 8,13 16,5"></polygon>
                  </g>
                </svg>
              </div> */}
            </div>
          </div>
          <div className="md:mx-auto md:py-16 py-6">
            {/* <MantineReactTable   table={table} /> */}

            <MantineReactTable table={table} />

            {showReceiptPopup && (
              <ReceiptPopup
                onClose={() => {
                  setShowReceiptPopup(false);
                  setReceiptPopupData(null);
                }}
                rowData={receiptPopupData}
              />
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default DonorsHistory;
