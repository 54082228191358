import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import { TQ_URL } from "../../utility/baseUrl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "../Common/Tooltip";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader
import { Helmet } from "react-helmet";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import HowWeWorkModal from "../HowWeWork/HowWeWorkModal";
import ReceiptPopup from "../Common/ReceiptPopup";
import HowWeWork from "../HowWeWork/HowWeWork";
import AddBankDetails from "../Profile/AddBankDetails";
import AddCausePopup from "../Cause/AddCausePopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomePage = () => {
  const shareText = "Check out this fundraiser on my website!";
  const [counter, setCounter] = useState(1);
  const [liveFeeds, setLiveFeeds] = useState([]);
  const [topFundraiser, setTopFundraiser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topFundLoading, setTopFundLoading] = useState(true);
  const isMounted = useRef(true);
  const navigate = useNavigate();

  const [showReceiptPopup, setShowReceiptPopup] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [receiptPopupData, setReceiptPopupData] = useState(null);
  const [config, setConfig] = useState({}); // State to store config data
  const [pageBaseUrl, setPageBaseUrl] = useState("");

  const pageTitle = "Your Page Title";
  const pageDescription = "Description of your content";

  var pageUrl;

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        const baseUrl = data.redirect_uri.replace("oauth2/redirect", "fundraiser");
        setPageBaseUrl(baseUrl);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    if (config.partnerId) {
      const showModalFlag = sessionStorage.getItem("showModal");

      if (!showModalFlag || showModalFlag === "false") {
        setShowModal(true);
        console.log("found");
      } else {
        console.log("not found");
      }

      window.scrollTo(0, 0);
      if (isMounted.current) {
        fetchLiveFeeds();
        fetchTopFundraiserList();
        isMounted.current = false;
      }

      console.log(showModal);
    }
  }, [config.partnerId]);

  const closeModal = () => {
    setShowModal(false);
    sessionStorage.setItem("showModal", "true");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [counter]);

  const shareOnWhatsApp = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;

    const shareText = "Check out this fundraiser on my website!";

    console.log("share on whats app called");
    // Replace 'your-share-url' with the actual URL you want to share
    const whatsappLink = `https://api.whatsapp.com/send?text=${`${shareText} ${pageUrl}`}`;

    // Open a new window with the WhatsApp share link
    window.open(whatsappLink, "_blank");
  };

  const shareOnTwitter = (fundraiserId) => {
    console.log("sharing the content on twitter");
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      "Check out this fundraiser on my website!"
    );
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
      pageUrl
    )}`;

    window.open(twitterShareUrl, "_blank");
  };
  const shareOnFacebook = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      "Check out this fundraiser on my website!"
    );

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      pageUrl
    )}&quote=${shareText}`;

    //   `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}&quote=${shareText}`

    window.open(facebookLink, "_blank");
  };

  const fetchLiveFeeds = async () => {
    try {
      const partnerId = config.partnerId;
      if (!partnerId) {
        throw new Error("Partner ID is not defined");
      }

      const response = await axios.get(`${TQ_URL}/getLiveFeeds`, {
        params: {
          partnerId: partnerId,
        },
      });

      if (response.data.status === "success") {
        setLiveFeeds(response.data.data);
      } else {
        console.error("Error fetching live feeds:", response.data.message);
        // toast.error(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Extract and log the message from the response
        let errorMessage = error.response.data.message;
       // toast.error("Error fetching live feeds: " + errorMessage);
      } else {
        // Fallback for other errors
        console.error("Error fetching live feeds:", error);
     //   toast.error("Error fetching live feeds: " + error.message);
      }
      setLoading(false);
    }
  };

  const fetchTopFundraiserList = async () => {
    try {
      const partnerId = config.partnerId;
      if (!partnerId) {
        console.error("Partner ID is not defined");
        toast.error("Partner ID is not defined. Please contact support.");
        setTopFundLoading(false);
        return;
      }

      const response = await axios.get(`${TQ_URL}/getTopFundraiserList`, {
        params: {
          partnerId: partnerId,
        },
      });

      console.log(response.data);
      if (response.status === 200) {
        setTopFundraiser(response.data);
      } else if (response.status === 404) {
        // toast.error("Fundraiser list not available.");
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }

      setTopFundLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.error(
          "Error fetching top fundraiser list:",
          error.response.data
        );
      //  toast.error("An error occurred: " + error.response.data);
      } else {
        console.error("Error fetching top fundraiser list:", error.message);
      //  toast.error("An unexpected error occurred. Please try again later.");
      }
      setTopFundLoading(false);
    }
  };
  const sliderSettingsFeedsForRightBlock = {
    dots: false,
    infinite: true,
    speed: 2000,
    fade: true,
    slidesToShow: Math.min(1, liveFeeds.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderSettingsFeeds = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: Math.min(3, liveFeeds.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderSettingsFundarisers = {
    dots: false,
    infinite: true,

    speed: 1000,
    slidesToShow: Math.min(3, topFundraiser.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // hide live feed

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [liveFeedsHeader, setLiveFeedsHeader] = useState(null);
  const [footer, setFooter] = useState(null);

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  useEffect(() => {
    setLiveFeedsHeader(document.querySelector(".liveFeedsHeader"));
    setFooter(document.querySelector(".why-us-sec"));

    function handleScroll() {
      const currentScroll =
        window.pageYOffset || document.documentElement.scrollTop;

      // Check if liveFeedsHeader is not null before accessing its style property
      if (liveFeedsHeader) {
        if (currentScroll > lastScrollTop && footer && !isInViewport(footer)) {
          // Downscrolling and footer is not in viewport
          liveFeedsHeader.style.visibility = "hidden"; // Hide the header
        } else {
          // Upscrolling or footer is in viewport
          liveFeedsHeader.style.visibility = "visible"; // Show the header
        }
      }

      // Update lastScrollTop
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, footer, liveFeedsHeader]);

  const handleDonateNowClick = () => {
    if (localStorage.getItem("accessToken") !== null) {
      navigate("/donate");
    } else {
      navigate("/login");
    }
  };

  return (
    <div>
      <Header />

      <HowWeWorkModal showModal={showModal} onClose={closeModal} />
      {/* )} */}

      {/* Banner Section */}
      <div className="landing-page-banner-section text-white px-8 flex justify-between w-full flex-col md:flex-row">
        <div className="flex items-center fade-in-left">
          <div>
            <p className="banner-text-1 font-semibold font-family-poppins">
              Seeking Financial Assistance For Your
            </p>
            <h1 className="banner-text-2">
              Medical <span className="yellow-texture-bg ">Care?</span>
            </h1>
            <p className="banner-text-1 font-extralight mb-10 font-family-poppins">
              Don't worry you've come to the right platform.
            </p>
            {/* <p className="banner-text-1 !text-lg font-light mb-6 font-family-poppins">
              With{" "}
            
              minimal platform fee, you can raise funds too!
            </p> */}
            <div className="flex space-x-4">
              <Link to="/createfundraiser">
                <button className="btn-style-3 py-2 px-4 rounded">
                  Start Free Fundraiser{" "}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="liveFeedsHeader">
          <div className=" max-w-screen-md mx-auto p-4 md:max-w-screen-lg lg:max-w-screen-xl xl:max-w-screen-2xl">
            {/* Dummy box to display live feeds */}
            {liveFeeds.length > 0 && !showModal && (
              <h2 className="banner-text-1 font-semibold font-family-poppins ml-72">
                Live Feeds
              </h2>
            )}

            {!showModal ? (
              <div className="max-w-xl mx-auto h-64 md:h-60 lg:h-128 xl:h-144">
                <Slider {...sliderSettingsFeedsForRightBlock}>
                  {liveFeeds.map((donation, index) => (
                    <div
                      key={index}
                      className="donation-boxLiveFeed mx-56 text-center !w-1/2 "
                    >
                      <div className="slider-image-container">
                        {/* Image */}
                        {donation.imageList && donation.imageList.length > 0 ? (
                          <img
                            // src={`assets/USNP_Document/${data.imageList[0]}`}
                            src={donation.imageList[0]}
                            alt="Data Image"
                            className=" object-cover mb-5 !rounded-t-3xl h-[163px] w-full "
                            onError={(e) => {
                              e.target.src = "/assets/images/defaultFund.jpg"; // Clear src to avoid displaying broken image icon
                            }}
                          />
                        ) : (
                          <img
                            src="/assets/images/defaultFund.jpg"
                            alt="Default Image"
                            className=" object-cover mb-5 !rounded-t-3xl  h-[163px] w-full"
                          />
                        )}
                      </div>
                      {/* Text */}
                      <div className="donation-description-boxLiveFeed ">
                        <p className="description-text text-gray-500 mb-4 truncate">
                          {donation.donorName} has donated{" "}
                          <span className="text-2xl font-bold italic ">
                            {/* ${counter * (donation.donatedAmt / 10).toFixed(2)}{" "} */}
                            {donation.donatedAmt} {/* Counter effect */}
                          </span>{" "}
                          to{donation.fundraiserName} for{" "}
                          <p className="truncate">{donation.causeName}</p>{" "}
                        </p>

                        <div className="pb-6 flex  justify-center items-center space-x-3 social-icon-box">
                          {/* twitter Icon */}

                          <Tooltip text="Share on Twitter">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              enable-background="new 0 0 72 72"
                              viewBox="0 0 72 72"
                              className="w-8"
                              id="twitter-x"
                              onClick={() => {
                                shareOnTwitter(donation.fundraiserId);
                              }}
                            >
                              <switch>
                                <g>
                                  <path
                                    d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
			h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                                  ></path>
                                </g>
                              </switch>
                            </svg>
                          </Tooltip>

                          <div>
                            <Tooltip text="Share on WhatsApp">
                              <svg
                                viewBox="0 0 48 48"
                                className="w-6"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                fill="#000000"
                                onClick={() => {
                                  shareOnWhatsApp(donation.fundraiserId);
                                }}
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <title>Whatsapp-color</title>{" "}
                                  <desc>Created with Sketch.</desc>{" "}
                                  <defs> </defs>{" "}
                                  <g
                                    id="Icons"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    {" "}
                                    <g
                                      id="Color-"
                                      transform="translate(-700.000000, -360.000000)"
                                      fill="#67C15E"
                                    >
                                      {" "}
                                      <path
                                        d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                                        id="Whatsapp"
                                      >
                                        {" "}
                                      </path>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </Tooltip>
                          </div>

                          {/* Facebook Icon */}

                          <Tooltip text="Share on Facebook">
                            <svg
                              viewBox="-5 0 20 20"
                              className="w-6"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              fill="#000000"
                              onClick={() => {
                                shareOnFacebook(donation.fundraiserId);
                              }}
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <title>facebook [#176]</title>{" "}
                                <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                                <g
                                  id="Page-1"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  {" "}
                                  <g
                                    id="Dribbble-Light-Preview"
                                    transform="translate(-385.000000, -7399.000000)"
                                    fill="#4267b2"
                                  >
                                    {" "}
                                    <g
                                      id="icons"
                                      transform="translate(56.000000, 160.000000)"
                                    >
                                      {" "}
                                      <path
                                        d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                        id="facebook-[#176]"
                                      >
                                        {" "}
                                      </path>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          </Tooltip>
                        </div>

                        {/* User Avatars */}
                        {/* <div className="flex mb-4 items-center">
                 {donation.supporters.map((avatar, idx) => (
                    <img
                      key={idx}
                      src={`assets/images/${avatar}`}
                      alt={`User ${idx + 1}`}
                      className="h-6 w-6 rounded-full"
                    />
                  ))}
                  <p className="text-xs ps-3"> and others donated recently</p>
                </div> */}

                        {/* Green Progress Bar */}
                        <div className="progress-bar-green mb-2">
                          <div
                            className="progress-bar-fill-green rise-in-left"
                            style={{
                              width: `${
                                (counter / 10) *
                                donation.amountAchivedInPercetage
                              }%`,
                            }}
                          />
                        </div>

                        {/* Donation Information */}
                        <p className="donation-info-text-box mb-2">
                          <span className="donation-info-numbers">
                            {/* ${counter * (donation.amtAchieved / 10).toFixed(2)}{" "} */}
                            {donation.amtAchieved} {/* Counter effect */}
                          </span>{" "}
                          Raised,{" "}
                          <span className="donation-info-numbers">
                            {donation.donorCount}
                          </span>{" "}
                          donors{" "}
                        </p>
                      </div>
                    </div>
                  ))}
                </Slider>
                {/* )} */}
              </div>
            ) : null}
          </div>
        </div>

        {/* Counter Section */}
        <div className="flex flex-col items-end justify-center md:ms-0 md:pe-20 2xl:pb-20">
          {/* <div
            className="text-dark text-right flex space-x-5 "
            style={{ marginTop: "19.5rem" }}
          >
            <p className="counter-text-1  font-bold">{counter}+</p>
            <p className="banner-text-1 font-semibold mb-2 text-left counter-text-2">
              Services We <br />
              Provide
            </p>
          </div> */}
        </div>
      </div>

      {/* Crowdfunding Categories Section */}
      <div className=" p-8 flex flex-col items-center pb-20">
        <h2 className="txt-style-7 mb-6 font-family-poppins text-center">
          Crowdfunding Categories
        </h2>
        <div className="flex md:space-x-10  flex-col md:flex-row space-y-10 md:space-y-0">
          {/* Icon Box 1 */}
          <div className="text-center icon-box">
            <img
              src="/assets/images/pediatrics.svg"
              alt="Logo"
              className="h-14"
            />
            <p className="text-sm font-semibold">Child Health</p>
          </div>

          {/* Icon Box 2 */}
          <div className="text-center icon-box">
            <img src="/assets/images/cancer.svg" alt="Logo" className="h-14 " />
            <p className="text-sm font-semibold">Cancer</p>
          </div>

          {/* Icon Box 3 */}
          <div className="text-center icon-box">
            <img src="/assets/images/heart.svg" alt="Logo" className="h-14 " />
            <p className="text-sm font-semibold">NGO</p>
          </div>

          {/* Icon Box 4 */}
          <div className="text-center icon-box">
            <img src="/assets/images/user.svg" alt="Logo" className="h-14 " />
            <p className="text-sm font-semibold">Personal Cause</p>
          </div>

          {/* Icon Box 5 */}
          <div className="text-center icon-box">
            <img src="/assets/images/phone.svg" alt="Logo" className="h-14 " />
            <p className="text-sm font-semibold">Emergency </p>
          </div>

          {/* Icon Box 6 */}
          <div className="text-center icon-box">
            <img src="/assets/images/kidney.svg" alt="Logo" className="h-14 " />
            <p className="text-sm font-semibold">Transplant </p>
          </div>
        </div>
      </div>

      {/* Live Feed Section*/}

      <div className="live-feed-sec md:py-10">
        <h2 className="txt-style-7 mb-8 font-family-poppins text-center">
          Live Feeds
        </h2>
        {loading ? (
          <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
            <Triangle
              height="120"
              width="120"
              color="blue"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
        ) : liveFeeds.length === 0 ? (
          <p className="mx-auto text-center text-lg font-semibold mt-5">
            No Data Found
          </p>
        ) : (
          <Slider {...sliderSettingsFeeds} className="container ">
            {liveFeeds.map((donation, index) => (
              <Link to={`/fundraiser/${donation.fundraiserId}`}>
                <div
                  key={index}
                  className="donation-box text-center  !w-11/12 md:!w-4/5 md:mx-10 mx:2 mb-10 "
                >
                  <div className="">
                    {/* Image */}
                    {donation.imageList && donation.imageList.length > 0 ? (
                      <img
                        src={donation.imageList[0]}
                        alt="Data Image"
                        className="w-full object-cover mb-10 !rounded-t-3xl h-[210px]"
                        onError={(e) => {
                          e.target.src = "/assets/images/defaultFund.jpg"; // Clear src to avoid displaying broken image icon
                        }}
                      />
                    ) : (
                      <img
                        src="/assets/images/defaultFund.jpg"
                        alt="Default Image"
                        className="w-full object-cover mb-10 !rounded-t-3xl  h-[210px]"
                      />
                    )}
                  </div>
                  {/* Text */}
                  <div className="donation-description-box ">
                    <p className="description-text text-gray-500 mb-4 truncate rotate-text">
                      {donation.donorName} has donated{" "}
                      <span className="text-2xl font-bold italic ">
                        {/* ${counter * (donation.donatedAmt / 10).toFixed(2)}{" "} */}
                        {donation.donatedAmt} {/* Counter effect */}
                      </span>{" "}
                      to{donation.fundraiserName} for{" "}
                      <p className="truncate">{donation.causeName}</p>{" "}
                    </p>

                    <div className="pb-6 flex justify-center items-center space-x-3 social-icon-box">
                      {/* twitter Icon */}

                      <Tooltip text="Share on Twitter">
                        {/* <svg
                        viewBox="0 0 24 24"
                        className="w-8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          shareOnTwitter(donation.fundraiserId);
                        }}
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M23 2.00001C22 2.87501 20.9375 3.50001 19.75 3.81251C20.9375 3 21.9375 1.81251 22.25 0.312508C21.1875 1.18751 19.9375 1.81251 18.625 2.06251C17.6875 0.812508 16.3125 0 14.875 0C12.4375 0 10.25 2.18751 10.25 4.87501C10.25 5.31251 10.3125 5.75 10.4375 6.125C6.9375 5.93751 3.75 4.12501 1.5 1.62501C1.125 2.31251 0.9375 3.12501 0.9375 3.93751C0.9375 5.68751 1.8125 7.18751 3.0625 7.93751C2.3125 7.87501 1.625 7.62501 1.0625 7.25C1.0625 7.25 1.0625 7.31251 1.0625 7.37501C1.0625 9.62501 2.875 11.4375 5.125 11.8125C4.5 11.9375 3.8125 12 3.125 12C2.5 12 1.875 11.9375 1.3125 11.8125C1.9375 13.625 3.25 15.0625 5 15.125C4.125 15.6875 3.25 16 2.3125 16C2.0625 16 1.8125 16 1.5625 15.9375C2.0625 17.6875 3.3125 19.125 5 19.25C4.25 19.375 3.4375 19.4375 2.625 19.4375C2.3125 19.4375 2 19.375 1.75 19.3125C3.4375 20.8125 5.375 21.625 7.375 21.625C14.875 21.625 19 15.3125 19 8.5C19 8.31251 19 8.12501 18.9375 7.93751C20.125 7.18751 21.125 5.87501 21.5 4.12501C22.5625 3.68751 23.5 2.87501 23 2.00001Z"
                            fill="#1DA1F2"
                          ></path>{" "}
                        </g>
                      </svg> */}

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 72 72"
                          viewBox="0 0 72 72"
                          className="w-8"
                          id="twitter-x"
                          onClick={() => {
                            shareOnTwitter(donation.fundraiserId);
                          }}
                        >
                          <switch>
                            <g>
                              <path
                                d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
			h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                              ></path>
                            </g>
                          </switch>
                        </svg>
                      </Tooltip>

                      <div>
                        <Tooltip text="Share on WhatsApp">
                          <svg
                            viewBox="0 0 48 48"
                            className="w-6"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            fill="#000000"
                            onClick={() => {
                              shareOnWhatsApp(donation.fundraiserId);
                            }}
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <title>Whatsapp-color</title>{" "}
                              <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                              <g
                                id="Icons"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                {" "}
                                <g
                                  id="Color-"
                                  transform="translate(-700.000000, -360.000000)"
                                  fill="#67C15E"
                                >
                                  {" "}
                                  <path
                                    d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                                    id="Whatsapp"
                                  >
                                    {" "}
                                  </path>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </Tooltip>
                      </div>

                      {/* Facebook Icon */}

                      <Tooltip text="Share on Facebook">
                        <svg
                          viewBox="-5 0 20 20"
                          className="w-6"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          fill="#000000"
                          onClick={() => {
                            shareOnFacebook(donation.fundraiserId);
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <title>facebook [#176]</title>{" "}
                            <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                            <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              {" "}
                              <g
                                id="Dribbble-Light-Preview"
                                transform="translate(-385.000000, -7399.000000)"
                                fill="#4267b2"
                              >
                                {" "}
                                <g
                                  id="icons"
                                  transform="translate(56.000000, 160.000000)"
                                >
                                  {" "}
                                  <path
                                    d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                    id="facebook-[#176]"
                                  >
                                    {" "}
                                  </path>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </Tooltip>
                    </div>

                    {/* Green Progress Bar */}
                    <div className="progress-bar-green mb-2">
                      <div
                        className="progress-bar-fill-green rise-in-left"
                        style={{
                          width: `${
                            (counter / 10) * donation.amountAchivedInPercetage
                          }%`,
                        }}
                      />
                    </div>

                    {/* Donation Information */}
                    <p className="donation-info-text mb-2">
                      <span className="donation-info-numbers">
                        {/* ${counter * (donation.amtAchieved / 10).toFixed(2)}{" "} */}
                        {donation.amtAchieved} {/* Counter effect */}
                      </span>{" "}
                      Raised,{" "}
                      <span className="donation-info-numbers">
                        {donation.donorCount}
                      </span>{" "}
                      donors{" "}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        )}
        {/* <p className="text-black font-medium md:mt-10 pb-10 md:pb-0 italic">Discover More Stories</p> */}
      </div>

      {/* New Section Below the "Live Feeds" */}
      <div className=" md:p-8  p-5 flex flex-col md:flex-row items-center justify-center md:py-20">
        {/* Left Div with Image and Counter */}
        <div className="2xl:w-2/5  w-full md:w-1/3 md:pr-8 relative ">
          {/* Image */}
          <img
            src="/assets/images/newCroppedImg.png"
            alt="Image"
            className="object-cover mb-6 md:mb-0 below-live-feed-img w-full h-auto"
          />
          {/* Counter with Blue Background */}
          <div className="counter-overlay  bottom-16 left-16 right-0 bg-blue-500 text-white text-center p-2">
            <p className="text-4xl font-bold ">
              {counter}+{" "}
              <p className="text-xs font-semibold blue-box-text">
                Happy Clients{" "}
              </p>
            </p>
          </div>
        </div>

        {/* Right Div with Heading, Paragraph, Button, and Counter Number */}
        <div className="2xl:w-1/3 md:w-1/2 flex flex-col items-start md:text-left ">
          <h2 className="text-lg md:text-2xl font-semibold mb-4">
            Contribute Monthly
            <br />
            To Preserve Innumerable Lives
          </h2>
          <p className="text-black font-medium md:mb-10 mb-5 text-sm md:text-md">
            "Giving Every Month" is a monthly subscription program specifically
            crafted to support patients in obtaining critical and life-saving
            medical care promptly.
          </p>
          <button
            className="btn-style-1 py-2 text-white px-6 rounded mb-4"
            onClick={handleDonateNowClick}
          >
            Give Every Month
          </button>
          <div className="counter-with-text">
            <p className="md:text-2xl font-bold mb-2 italic">
              <span className="orange-txt ">100%</span> Transparency
            </p>
          </div>
        </div>
      </div>

      <div className="top-fundraiser-sec p-8 flex flex-col items-center pb-20">
        <h2 className="txt-style-7 mb-8 font-family-poppins text-center">
          Our Recent Fundraisers
        </h2>
        {topFundLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
              <Triangle
                height="120"
                width="120"
                color="blue"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          </div>
        ) : topFundraiser.length === 0 ? (
          <p className="mx-auto text-center text-lg font-semibold mt-5">
            No Data Found
          </p>
        ) : (
          <Slider {...sliderSettingsFundarisers} className="container">
            {topFundraiser.map((data, index) => (
              <div
                key={index}
                className="donation-box text-center !w-11/12 md:!w-4/5 md:mx-10 mx-2 mb-10"
              >
                {/* Image */}
                {/* Check if the image exists in the data.imageList array */}
                {data.imageList && data.imageList.length > 0 ? (
                  <img
                    // src={`assets/USNP_Document/${data.imageList[0]}`}
                    src={data.imageList[0]}
                    alt="Data Image"
                    className="w-full object-cover mb-6 h-[210px] rounded-xl"
                    onError={(e) => {
                      e.target.src = "/assets/images/defaultFund.jpg"; // Clear src to avoid displaying broken image icon
                    }}
                  />
                ) : (
                  <img
                    src="/assets/images/defaultFund.jpg"
                    alt="Default Image"
                    className="w-full object-cover mb-6 h-[210px] rounded-xl"
                  />
                )}
                {/* Text with Center Alignment */}
                <div className="text-center px-10">
                  <p className="description-text text-gray-500 mb-4 px-10 truncate">
                    {" "}
                    {data.title}
                  </p>

                  {/* Social Icons */}
                  {/* Gamil Icon */}
                  <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={pageDescription} />
                    <meta property="og:title" content={data.title} />
                    <meta property="og:description" content={pageDescription} />
                    <meta
                      property="og:image"
                      content="https://prabalta.s3.eu-north-1.amazonaws.com/202312251/add-details-self-bg.jpg"
                    />
                    <meta property="og:image:width" content="500" />{" "}
                    <meta property="og:image:height" content="630" />
                    <meta content="image/*" property="og:image:type" />
                    <meta property="og:url" content={pageUrl} />
                  </Helmet>
                  <div className="pb-6 flex justify-center items-center space-x-3 social-icon-box">
                    {/* twitter Icon */}

                    <Tooltip text="Share on Twitter">
                      {/* <svg
                        viewBox="0 0 24 24"
                        className="w-8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          shareOnTwitter(data.fundraiserId);
                        }}
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M23 2.00001C22 2.87501 20.9375 3.50001 19.75 3.81251C20.9375 3 21.9375 1.81251 22.25 0.312508C21.1875 1.18751 19.9375 1.81251 18.625 2.06251C17.6875 0.812508 16.3125 0 14.875 0C12.4375 0 10.25 2.18751 10.25 4.87501C10.25 5.31251 10.3125 5.75 10.4375 6.125C6.9375 5.93751 3.75 4.12501 1.5 1.62501C1.125 2.31251 0.9375 3.12501 0.9375 3.93751C0.9375 5.68751 1.8125 7.18751 3.0625 7.93751C2.3125 7.87501 1.625 7.62501 1.0625 7.25C1.0625 7.25 1.0625 7.31251 1.0625 7.37501C1.0625 9.62501 2.875 11.4375 5.125 11.8125C4.5 11.9375 3.8125 12 3.125 12C2.5 12 1.875 11.9375 1.3125 11.8125C1.9375 13.625 3.25 15.0625 5 15.125C4.125 15.6875 3.25 16 2.3125 16C2.0625 16 1.8125 16 1.5625 15.9375C2.0625 17.6875 3.3125 19.125 5 19.25C4.25 19.375 3.4375 19.4375 2.625 19.4375C2.3125 19.4375 2 19.375 1.75 19.3125C3.4375 20.8125 5.375 21.625 7.375 21.625C14.875 21.625 19 15.3125 19 8.5C19 8.31251 19 8.12501 18.9375 7.93751C20.125 7.18751 21.125 5.87501 21.5 4.12501C22.5625 3.68751 23.5 2.87501 23 2.00001Z"
                            fill="#1DA1F2"
                          ></path>{" "}
                        </g>
                      </svg> */}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 72 72"
                        viewBox="0 0 72 72"
                        className="w-8"
                        id="twitter-x"
                        onClick={() => {
                          shareOnTwitter(data.fundraiserId);
                        }}
                      >
                        <switch>
                          <g>
                            <path
                              d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
			h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                            ></path>
                          </g>
                        </switch>
                      </svg>
                    </Tooltip>

                    <div>
                      <Tooltip text="Share on WhatsApp">
                        <svg
                          viewBox="0 0 48 48"
                          className="w-6"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          fill="#000000"
                          onClick={() => {
                            shareOnWhatsApp(data.fundraiserId);
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <title>Whatsapp-color</title>{" "}
                            <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                            <g
                              id="Icons"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              {" "}
                              <g
                                id="Color-"
                                transform="translate(-700.000000, -360.000000)"
                                fill="#67C15E"
                              >
                                {" "}
                                <path
                                  d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                                  id="Whatsapp"
                                >
                                  {" "}
                                </path>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </Tooltip>
                    </div>

                    {/* Facebook Icon */}

                    <Tooltip text="Share on Facebook">
                      <svg
                        viewBox="-5 0 20 20"
                        className="w-6"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        fill="#000000"
                        onClick={() => {
                          shareOnFacebook(data.fundraiserId);
                        }}
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <title>facebook [#176]</title>{" "}
                          <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                          <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            {" "}
                            <g
                              id="Dribbble-Light-Preview"
                              transform="translate(-385.000000, -7399.000000)"
                              fill="#4267b2"
                            >
                              {" "}
                              <g
                                id="icons"
                                transform="translate(56.000000, 160.000000)"
                              >
                                {" "}
                                <path
                                  d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                  id="facebook-[#176]"
                                >
                                  {" "}
                                </path>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </div>

                  {/* Donation Information */}
                  <p className="donation-info-text mb-2 flex justify-between">
                    <div>
                      <span className="donation-info-numbers">
                        {/* ${counter * (data.amtAchieved / 10).toFixed(2)}{" "} */}
                        {data.amtAchieved} {/* Counter effect */}
                      </span>
                      Raised
                    </div>
                    <span className="donation-info-numbers">
                      {" "}
                      {data.amountAchivedInPercetage}%
                    </span>
                  </p>

                  {/* Progress Bar */}
                  <div className="progress-bar-green mb-2">
                    <div
                      className="progress-bar-fill-green rise-in-left"
                      style={{
                        width: `${
                          (counter / 10) * data.amountAchivedInPercetage
                        }%`,
                      }}
                    />
                  </div>

                  {/* Donate Button */}
                  <Link to={`/fundraiser/${data.fundraiserId}`}>
                    <button className="btn-style-1 py-2 text-white px-6 rounded my-4 ">
                      Donate Now
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>

      {/* <div className="why-us-sec p-8 flex flex-col items-center pb-20">
        <h2 className="txt-style-3 mb-8 font-family-poppins !text-3xl">
          Why Us?
        </h2>

       
        <div className="flex md:space-x-28 flex-col md:flex-row space-y-10 md:space-y-0">
         
          <div className="text-center">
            <p className="text-4xl font-bold mb-2">{counter + 24990}+</p>
            <p className="text-lg font-semibold mb-4 italic text-gray-500">
              Patients Funded
            </p>
          </div>

        
          <div className="text-center">
            <p className="text-4xl font-bold mb-2">24/7</p>
            <p className="text-lg font-semibold mb-4 italic text-gray-500">
              Personalized Expert Assistance
            </p>
          </div>

         
          <div className="text-center">
            <p className="text-4xl font-bold mb-2">{counter + 20} Lakh +</p>
            <p className="text-lg font-semibold mb-4 italic text-gray-500">
              Donor Community
            </p>
          </div>

         
          <div className="text-center">
            <p className="text-4xl font-bold mb-2">Fast Review</p>
            <p className="text-lg font-semibold mb-4 italic text-gray-500">
              Of Fundraisers
            </p>
          </div>
          <ToastContainer theme="colored" />
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default HomePage;
