import React, { useCallback, useEffect, useState, useRef } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Box, Button } from "@mantine/core";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import TopHeader from "../Home/TopHeader";
import HistorySidebar from "../Home/HistorySidebar";

import {
  getRequestWithTokenWithoutData,
  patchRequestWithTokenWithData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditFundraiser from "../BrowseFundraiser/EditFundraiser";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    accessorKey: "fundraiserId",
    header: "ID",
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "formattedStartDate",
    header: "Date",
    enableColumnFilter: false,
    enableSorting: false,
  },

  {
    accessorKey: "title",
    header: "Fundraiser title",
    enableColumnFilter: false,
    enableSorting: false,
  },

  {
    accessorKey: "causeName",
    header: "Cause",
    enableColumnFilter: false,
    enableSorting: false,
  },

  {
    accessorKey: "statusName",
    header: "Status",
    enableColumnFilter: false,
    enableSorting: false,
    Cell: ({ cell }) => (
      <Box
        sx={(theme) => ({
          backgroundColor:
            cell.getValue() === "Active"
              ? "#DAF4EB"
              : cell.getValue() === "Stop"
              ? "#FDE4E4"
              : cell.getValue() === "Pending"
              ? "#FFE0A1"
              : "inherit",
          color:
            cell.getValue() === "Active"
              ? theme.colors.green[9]
              : cell.getValue() === "Stop"
              ? "#FF0000"
              : cell.getValue() === "Pending"
              ? "#FFA200"
              : "inherit",
          borderRadius: "4px",
          padding: "4px",
          width: "80px",
          textAlign: "center",
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
];

const FundraiserHistory = () => {
  const [data, setData] = useState([]);
  const isMounted = useRef(true);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(0); // Add key state

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [buttonText, setButtonText] = useState("");
  const [editPopupOpen, setEditPopupOpen] = useState({
    isOpen: false,
    rowData: null,
  });

  const updateRowInData = (updatedRow) => {
    console.log(updatedRow);
    setData((prevData) =>
      prevData.map((row) =>
        row.fundraiserId === updatedRow.fundraiserId ? updatedRow : row
      )
    );
  };

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    // if (isMounted.current) {
    getFundraiserHistory();
    // isMounted.current = false;
    // }
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting,
  ]);

  var initialText = "";

  const getFundraiserHistory = async () => {
    const url = new URL(`${TQ_URL}/getAllFundraiserMadeByLoginUser`);

    console.log(url.href);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);

    try {
      const response = await getRequestWithTokenWithoutData(url.toString());

      console.log(response.dataList);
      setData(response.dataList);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching the Fundraiser's history:", error);

      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleEditFundraiser = (row) => {
    console.log(row);
  };

  const handleViewClick = (id) => {
    let url = `/fundraiser/${id}`;
    navigate(url);
  };

  const handleStopFundraising = (rowId, statusId) => {
    console.log(rowId);
    console.log(statusId);

    Swal.fire({
      title: "Are you sure?",
      text: "This action will update the status for your fundraiser",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: false,
    }).then((result) => {
      if (result.isConfirmed) {
        performStopFundraiser(rowId, statusId);
      } else {
      }
    });
  };

  const performStopFundraiser = async (rowId, statusId) => {
    try {
      let request = {
        fundraiserId: rowId,
        statusId: statusId,
      };
      const response = await patchRequestWithTokenWithData(
        `${TQ_URL}/stopFundraiser`,
        request
      );
      console.log(response);

      if (statusId === 1) {
        const updatedData = data.map((item) => {
          if (item.fundraiserId === rowId) {
            const updatedButtonText = "Start Fundraiser";
            setButtonText((prevButtonTexts) => ({
              ...prevButtonTexts,
              [rowId]: updatedButtonText,
            }));
            return { ...item, status: 4, statusName: "Stop" };
          }
          return item;
        });
        setData(updatedData);
      } else if (statusId === 4) {
        const updatedData = data.map((item) => {
          if (item.fundraiserId === rowId) {
            const updatedButtonText = "Stop Fundraiser";
            setButtonText((prevButtonTexts) => ({
              ...prevButtonTexts,
              [rowId]: updatedButtonText,
            }));
            return { ...item, status: 1, statusName: "Active" };
          }
          return item;
        });
        setData(updatedData);
      }

      setKey((prevKey) => prevKey + 1);
      console.log(data);
      successFundraiserStopped(statusId);
    } catch (error) {
      console.error("Error stopping fundraising:", error);
    }
  };

  const getButtonText = (fundraiserId, defaultText) => {
    return buttonText[fundraiserId] || defaultText;
  };

  const successFundraiserStopped = (statusId) =>
    statusId == 1
      ? toast.success("Fundraiser Stopped Successfully!")
      : toast.success("Fundraiser Started  Successfully!");
  const table = useMantineReactTable({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: "last",
    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,

    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <ActionIcon
          color="orange"
          onClick={() => {
            setEditPopupOpen({
              isOpen: true,
              rowData: row.original,
            });
          }}
        >
          <IconEdit />
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            handleViewClick(row.original.fundraiserId);
          }}
        >
          <IconEye />
        </ActionIcon>
        <Button
          onClick={() => {
            console.log(row.original.fundraiserId);
            handleStopFundraising(
              row.original.fundraiserId,
              row.original.status
            );
          }}
          variant="outline"
        >
          {getButtonText(
            row.original.fundraiserId,
            row.original.status === 1 ? "Stop Fundraiser " : "Start Fundraiser "
          )}
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <HistorySidebar />
        </div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">Fundraiser History</h2>
              </div>
              <div className="flex items-center space-x-4 mt-6 md:mt-0 justify-center"></div>
            </div>
          </div>
          <div className="container md:mx-auto md:py-16 py-6">
            <MantineReactTable key={key} table={table} />
          </div>
        </main>
      </div>

      {editPopupOpen.isOpen && (
        <EditFundraiser
          onClose={() => {
            setEditPopupOpen({ isOpen: false });
          }}
          fundraiserData={editPopupOpen.rowData}
          onUpdateRow={updateRowInData}
        />
      )}
      <ToastContainer theme="colored" />
    </>
  );
};

export default FundraiserHistory;
