import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import TopHeader from "./TopHeader";

const Header = () => {
  const location = useLocation();
  const [config, setConfig] = useState({});
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const handleKeyPress = (event) => {
    var path = location.pathname;
    if (event.key === "Enter") {
      redirectToAnotherPage(searchText);
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const redirectToAnotherPage = (searchText) => {
    const value = {
      keyword: searchText,
    };

    navigate("/browsefundraiser", { state: value });
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setSticky(offset > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  const [userData, setUserData] = useState(null);
  const isUserLoggedIn = () => {
    return userData !== null;
  };

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("userData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));

    const localStorageAddressData = localStorage.getItem("userAddressDetails");
    console.log(
      "Local Storage Address Data:",
      JSON.parse(localStorageAddressData)
    );

    setUserData(JSON.parse(localStorageData));
  };

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        // Check and replace the logo URL if necessary
        if (data.logoUrl.includes("/home/tqadmin/public_html")) {
          data.logoUrl = data.logoUrl.replace(
            "/home/tqadmin/public_html",
            "https://prabalta.org"
          );
        }
        setConfig(data);
      })
      .catch((error) => console.error("Error loading config.json:", error));

    logLocalData();
  }, []);

  const handleCreateFundraiserClick = () => {
    if (isUserLoggedIn() && localStorage.getItem("accessToken") !== null) {
      navigate("/createfundraiser");
    } else {
      navigate("/login");
    }
    setIsMobileMenuOpen(false);
  };

  const handleDonateNowClick = () => {
    if (isUserLoggedIn() && localStorage.getItem("accessToken") !== null) {
      navigate("/donate");
    } else {
      navigate("/login");
    }
    setIsMobileMenuOpen(false);
  };

  return (
    <div
      className={`w-full z-50 bg-white ${
        isSticky ? "sticky-header slide-in-up" : ""
      }`}
    >
      <TopHeader />
      {/* Main Header */}
      <div className="bg-white p-2 flex flex-col md:flex-row justify-between items-center px-4 md:px-10 2xl:px-20">
        {/* Logo */}
        <div className="mb-4 md:mb-0 flex justify-between w-full md:w-2/12 ">
          <Link to="/">
            <img
              src={config.logoUrl}
              alt="Logo"
              className="h-10 mx-auto sm:mx-0"
            />
          </Link>
          {/* Donate Now Button */}
          <a
            to="#"
            onClick={handleDonateNowClick}
            className="btn-style-1 text-white text-sm py-2 px-6 rounded block md:hidden"
          >
            Donate
          </a>
          {/* Hamburger Toggle for Mobile */}
          <div className="md:hidden flex items-center">
            <button
              className="text-dark-500 font-semibold focus:outline-none"
              onClick={toggleMobileMenu}
            >
              <FiMenu size={24} />
            </button>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="md:hidden fixed inset-0 bg-opacity-75 bg-gray-800 z-50">
            <AnimatePresence>
              <motion.div
                key="modal"
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="absolute right-0 bg-white border rounded shadow-md w-2/3 px-5 py-2 h-full"
              >
                <button
                  className="text-dark-500 font-semibold focus:outline-none absolute top-4 right-4"
                  onClick={toggleMobileMenu}
                >
                  <FiX size={24} />
                </button>

                <ul className="list-none p-2 text-black mt-6">
                  <li className="py-1 mb-4">
                    <Link
                      to="/browsefundraiser"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/browsefundraiser") && "active-link"
                      }`}
                    >
                      Browse Fundraisers
                    </Link>
                  </li>
                  <li className="py-1 mb-4">
                    <a
                      to="/#"
                      onClick={handleCreateFundraiserClick}
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/createfundraiser") && "active-link"
                      }`}
                    >
                      Create Fundraiser
                    </a>
                  </li>
                  <li className="py-1 mb-4">
                    <Link
                      to="/aboutus"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/aboutus") && "active-link"
                      }`}
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="py-1 mb-4">
                    <Link
                      to="/howwework"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/howwework") && "active-link"
                      }`}
                    >
                      How We Work
                    </Link>
                  </li>
                  <li className="py-1 mb-4">
                    <Link
                      to="/support"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/support") && "active-link"
                      }`}
                    >
                      Support
                    </Link>
                  </li>
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
        )}

        {/* Search Bar */}
        <div className="rounded p-1 flex w-full justify-between md:flex-grow-0 md:w-2/12">
          <input
            type="text"
            placeholder="Search...(Use enter key to search)"
            className="border outline-none py-2 ps-2 w-full md:w-4/5 header-search-box"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
          />
        </div>

        {/* Navigation Menus */}
        <div
          className={`text-sm 2xl:text-base flex space-x-4 md:space-x-8 md:flex ${
            isDropdownOpen ? "flex-col" : "hidden"
          }`}
        >
          <Link
            to="/browsefundraiser"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/browsefundraiser") && "active-link"
            }`}
          >
            Browse Fundraisers
          </Link>
          <a
            to="/#"
            onClick={handleCreateFundraiserClick}
            className={`text-dark-500 cursor-pointer font-semibold nav-item ${
              isActiveRoute("/createfundraiser") && "active-link"
            }`}
          >
            Create Fundraiser
          </a>
          <Link
            to="/aboutus"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/aboutus") && "active-link"
            }`}
          >
            About Us
          </Link>
          <Link
            to="/howwework"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/howwework") && "active-link"
            }`}
          >
            How We Work
          </Link>
          <Link
            to="/support"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/support") && "active-link"
            }`}
          >
            Support
          </Link>
        </div>

        {/* Donate Now Button */}
        <a
          to="#"
          onClick={handleDonateNowClick}
          className="btn-style-1 text-white py-2 px-4 rounded hidden md:block"
        >
          Donate Now
        </a>
      </div>
    </div>
  );
};

export default Header;
