import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import { getRequestWithTokenWithData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import EditFundraiser from "./EditFundraiser";
import "./fund.css"; // Import your custom CSS

const Fundraiser = ({ onComplete }) => {
  const [fund, setFund] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const videoRef = useRef(null);
  const isMounted = useRef(true);

  const handleSubmit = () => {
    const values = {
      fundraiserId: id,
      fundraiserName: fund.fundraiserName,
      remainingAmount: fund.goalAmt - fund.amtAchieved, // Calculate remaining amount
    };
  
    navigate("/transactiondetails", { state: values });
  };

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequestWithTokenWithData(
          `${TQ_URL}/getFundraiserProfileDetailsForPage`,
          {
            fundraiserId: id,
          }
        );
        console.log(response);
        setFund(response.data);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          console.error("Error:", error.response.data.error);
          toast.error("Failed to fetch data for fundraiser id.");
        } else {
          console.error(
            "Error fetching data for fundraiser id:",
            error.message
          );
          toast.error("Error fetching data for fundraiser id.");
        }
      }
    };

    if (isMounted.current) {
      fetchData();
      isMounted.current = false;
    }

    console.log("fundraiser id:" + id);
  }, [id]);

  const createMarkup = (content) => {
    return { __html: content };
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getFileExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  return (
    <div>
      <Header />
      <div className="fundraiser-banner-section">
        <div className="container mx-auto md:flex items-center justify-between">
          <div className="md:w-2/5 md:mx-4">
            {fund?.imageList && fund.imageList.length > 0 ? (
              <Slider {...sliderSettings}>
                {fund.imageList.map((image, index) => (
                  <div key={index} className="media-container">
                    {console.log(getFileExtension(image))}
                    {(getFileExtension(image) === "jpg" ||
                      getFileExtension(image) === "jpeg" ||
                      getFileExtension(image) === "png") && (
                      <img
                        src={image}
                        alt={`Slide ${index}`}
                        onError={(e) => {
                          e.target.src = "/assets/images/default.jpg";
                        }}
                      />
                    )}
                    {getFileExtension(image) === "mp4" && (
                      <video ref={videoRef} controls controlsList="nodownload">
                        <source src={image} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {(getFileExtension(image) === "doc" ||
                      getFileExtension(image) === "text") && (
                      <div className="bg-white border p-4 rounded-md">
                        <p className="text-sm font-normal">{image}</p>
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="media-container">
                <img
                  src="/assets/images/default.jpg"
                  alt="Fallback Image"
                />
              </div>
            )}
          </div>

          <div className="md:w-1/2 pr-8 md:py-20 py-10 mx-4 md:mx-0">
            <h2 className="text-2xl font-semibold mb-4 md:w-2/3 ">
              {fund.title}
            </h2>
            <h1 className="banner-text-2 leading-none">
              {fund.amountAchivedInPercetage}% Funded
              <br />
              In{" "}
              <span className="yellow-texture-bg text-blue-800">
                {fund.noOfDaysofAchivment} Days
              </span>
            </h1>
            <h1 className="banner-text-2 !text-white !text-6xl md:!text-9xl w-full ">
              Fundraiser{" "}
            </h1>
            <h2 className="text-2xl font-semibold mb-4 md:w-1/2">
              ${fund.amtAchieved} raised of ${fund.goalAmt}
            </h2>
            <h2 className="text-2xl font-semibold mb-4 w-1/2 text-blue-800 ">
              {fund.donorsCount} Donors
            </h2>

            <button
              className="btn-style-1 text-white px-4 py-2 rounded"
              onClick={handleSubmit}
              disabled={fund.amountAchivedInPercetage >= 100}
            >
              Donate Now
            </button>

            {editPopupOpen && (
              <EditFundraiser
                onClose={() => setEditPopupOpen(false)}
                fundraiserData={fund}
              />
            )}
          </div>
        </div>
      </div>

      <div className="container mx-auto md:py-20 md:flex justify-between overflow-x-hidden">
        <div className="md:w-4/6 mx-4 md:mx-4">
          <h2 className="text-2xl font-bold mb-4 w-1/2 font-family-poppins">
            Story
          </h2>

          <p
            className="font-normal font-family-poppins leading-8 mt-10"
            dangerouslySetInnerHTML={createMarkup(fund.desc)}
          ></p>
        </div>
        <div className="2xl:w-1/5 md:w-1/4 shadow-lg ps-14 py-6 w-9/12 mx-auto md:mx-0 my-6">
          <h2 className="text-2xl font-bold mb-4 font-family-poppins">
            All Donors ({fund.donorsCount})
          </h2>
          <div className="max-h-[32rem] overflow-y-auto pe-6">
            {fund?.donors?.map((item, index) => (
              <div key={index}>
                <h2 className="text-lg font-bold mb-1 font-family-poppins">
                  {" "}
                  ${item.donatedAmount} From {item.donorName} <br />{" "}
                </h2>
                <h2 className="text-sm font-normal mb-4 font-family-poppins">
                  {" "}
                  {item.title}{" "}
                </h2>
                <hr className="w-2/3 mb-6" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
      <Footer />
    </div>
  );
};

export default Fundraiser;
